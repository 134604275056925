<template>
  <div class="card card-body">
    <router-link :to="route" :title="title">
      <div class="media">
        <div class="align-self-center">
          <i :class="icon + ' text-' + color" />
        </div>
        <div class="media-body text-right mt-1">
          <h3 :class="'font-weight-semibold mb-0 text-' + color">{{ name }}</h3>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CardLink',

  props: ['name', 'title', 'route', 'color', 'icon'],
}
</script>
